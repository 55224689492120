import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import NumberInput from '../../../../components/NumberInput';
import constants from '../../../../config/constants';

const PrintSizes = ({ fileList, updateData, isMorePrints = false }) => {

    const isDarkMode = useSelector(state => state.app.isDarkMode);

    return (
        <div className="overflow-y-auto">
            <table className='w-full'>
                <thead>
                    <tr className='border-0 border-b border-b-gray-400 border-solid'>
                        <th className='border-gray-400 p-1 sm:p-2 md:p-4 border-b-1 border-b-solid w-1/6'></th>
                        <th className='p-1 sm:p-2 md:p-4 w-1/6'>
                            <h3 className='sm:font-bold'>4x6 Print</h3>
                            <small>$10 per photo</small>
                        </th>
                        <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <h3 className='sm:font-bold'>5x7 Print</h3>
                            <small>$15 per photo</small>
                        </th>
                        <th className='p-1 sm:p-2 md:p-4 w-1/6'>
                            <h3 className='sm:font-bold'>8x10 Print</h3>
                            <small>$24 per photo</small>
                        </th>
                        <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <h3 className='sm:font-bold'>11x14 Print</h3>
                            <small>$34 per photo</small>
                        </th>
                        <th className='p-1 sm:p-2 md:p-4 w-1/6'>
                            <h3 className='sm:font-bold'>16x20 Print</h3>
                            <small>$49 per photo</small>
                        </th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        fileList?.length > 0 && fileList?.map((file, index) =>
                            <tr key={index}>
                                <td className='p-1 sm:p-2 md:p-4'>
                                    {(isMorePrints && file.result) ? <img src={`${constants.SOCKET_URL}${file.result}`} className='rounded w-14 h-14 object-cover' alt="" /> : (file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} className='rounded w-14 h-14 object-cover' alt="" /> : <div className='flex justify-center items-center bg-gray-200 shadow rounded w-14 h-14'>
                                        <h1 className="m-0 font-bold text-purple-600">{index + 1}</h1>
                                    </div>)}
                                </td>
                                <td className='p-1 sm:p-2 md:p-4'>
                                    <NumberInput isDarkMode={isDarkMode} value={file.print1} onChange={(value) => {
                                        const files = fileList.map(file => ({ ...file }));
                                        files[index].print1 = value;
                                        updateData("fileList", files);
                                    }} />
                                </td>
                                <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                                    <NumberInput isDarkMode={isDarkMode} value={file.print2} onChange={(value) => {
                                        const files = fileList.map(file => ({ ...file }));
                                        files[index].print2 = value;
                                        updateData("fileList", files);
                                    }} />
                                </td>
                                <td className='p-1 sm:p-2 md:p-4'>
                                    <NumberInput isDarkMode={isDarkMode} value={file.print3} onChange={(value) => {
                                        const files = fileList.map(file => ({ ...file }));
                                        files[index].print3 = value;
                                        updateData("fileList", files);
                                    }} />
                                </td>
                                <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                                    <NumberInput isDarkMode={isDarkMode} value={file.print4} onChange={(value) => {
                                        const files = fileList.map(file => ({ ...file }));
                                        files[index].print4 = value;
                                        updateData("fileList", files);
                                    }} />
                                </td>
                                <td className='p-1 sm:p-2 md:p-4'>
                                    <NumberInput isDarkMode={isDarkMode} value={file.print5} onChange={(value) => {
                                        const files = fileList.map(file => ({ ...file }));
                                        files[index].print5 = value;
                                        updateData("fileList", files);
                                    }} />
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}

export default PrintSizes;
















