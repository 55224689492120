import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: "",
  email: "",
  option: "ready",
  artist: 'mid',
  fileList: [],
  delivery: 'free',
  address: {},
  note: "",
  orderId: "",
}

const additionalOrderSlice = createSlice({
  name: 'additionalOrder',
  initialState,
  reducers: {
    updateAdditionalOrderDetail(state, action) {
      if (action.payload.field) {
        state[action.payload.field] = action.payload.value;
      } else {
        for (const key in action.payload.value) {
          if (Object.hasOwnProperty.call(action.payload.value, key)) {
            state[key] = action.payload.value[key];
          }
        }
      }
    },
  },
});

export const { updateAdditionalOrderDetail } = additionalOrderSlice.actions;
export default additionalOrderSlice.reducer;
