import { combineReducers } from "@reduxjs/toolkit";
import additionalOrder from "./additionalOrder/additionalOrderSlice";
import app from "./app/appSlice";
import auth from "./auth/authSlice";
import cart from "./cart/cartSlice";
import order from "./order/orderSlice";
import plan from "./plan/planSlice";
import user from "./user/userSlice";

//Include all the reducer to combine and provide to configure store.
const rootReducer = {
    app,
    auth,
    plan,
    order,
    user,
    cart,
    additionalOrder,
};

export default combineReducers(rootReducer);
