import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Popconfirm, Radio, Row, Tabs, Typography, message } from 'antd';
import { debounce } from 'lodash';
import { PiFrameCornersLight } from 'react-icons/pi';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import constants, { ORDER_STATUS, PHOTO_STATE, PRINT_SIZES } from '../../../../config/constants';

import { savePhotoDir, savePrintSetting } from '../../../../services/orderAPI';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const PrintSettingContent = ({
  order,
  selectedFileIndex,
  updateData,
  previous,
  next,
}) => {

  const additionalOrder = useSelector(state => state.additionalOrder);

  const [landscape, setLandscape] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pSetting, setPSetting] = useState([]);
  const [crp, setCrp] = useState([]);
  const [selFile, setSelFile] = useState({});

  const previewImage = (i) => {
    let se = crp[i];
    if (!se) return `${constants.SOCKET_URL}${selFile.result}`;
    let x = se.x;
    let y = se.y;
    let w = se.width;
    let h = se.height;
    let scale = se.scale;
    let searchParams = new URLSearchParams({ x, y, w, h, scale });

    return `${constants.SOCKET_URL}${selFile.result}?${searchParams}`;
  }

  const handleWheel = debounce((e, i) => {
    let tmp = [...crp];
    // console.log(e, e.deltaY, tmp[i].scale);
    tmp[i] = { ...tmp[i], scale: (tmp[i].scale ? tmp[i].scale : 1) + ((e.deltaY < 0 ? 0.01 : -0.01)) };
    if (tmp[i].scale > 1) {
      tmp[i].scale = 1;
    } else if (tmp[i].scale <= 0.3) {
      tmp[i].scale = 0.3;
    }
    setCrp(tmp);
    // e.preventDefault();
  });


  useEffect(() => {

    if (additionalOrder && selectedFileIndex !== undefined) {
      console.log("additionalOrder", additionalOrder);
      let tmp = [], file = { ...additionalOrder.fileList[0] };
      // file.print = order.fileList[selectedFileIndex].print;
      // let bi = file.dimension.w / file.dimension.h;
      setLandscape(file.dimension.landscape);
      for (const i of [1, 2, 3, 4, 5]) {
        if (file[`print${i}`] > 0) {
          // console.log(`print${i}`, file[`print${i}`])
          tmp.push({
            ...centerCrop(
              makeAspectCrop(
                {
                  // You don't need to pass a complete crop into
                  // makeAspectCrop or centerCrop.
                  unit: '%',
                  width: 100,
                },
                (Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[file.dimension.landscape ? 0 : 1])),
                file.dimension.w,
                file.dimension.h
              ),
              file.dimension.w,
              file.dimension.h
            ),
            paper: `print${i}`,
            count: file[`print${i}`]
          });
        }
      }
      setCrp([...tmp]);
      setPSetting([...tmp]);
      setSelFile({ ...file, orderId: order._id, index: selectedFileIndex });
    }
  }, [additionalOrder, selectedFileIndex, order]);


  return (
    <div>
      {
        additionalOrder && selectedFileIndex !== undefined &&
        <Tabs
          defaultActiveKey="0"
          type="card"
          size="small"

          items={[1, 2, 3, 4, 5].filter((item) => selFile[`print${item}`] > 0).map((_, i) => {
            return {
              label: PRINT_SIZES[`print${_}`],
              key: i,
              children: <Row gutter={[24, 24]}>
                <Col span={16} onWheel={(e) => handleWheel(e, i)}>
                  <ReactCrop
                    crop={pSetting[i]}
                    aspect={Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])}
                    onComplete={(c, pc) => {
                      // console.log(c, pc);
                      let tmp = [...crp];
                      tmp[i] = { ...tmp[i], ...pc };
                      setCrp(tmp);
                    }}
                    className='relative w-full'
                    data-value={'12'}
                    onChange={(c, pc) => {
                      // console.log(pc);
                      let tmp = [...pSetting];
                      tmp[i] = { ...tmp[i], ...pc };
                      setPSetting(tmp);
                    }}>
                    <img className='top-0 absolute w-full mousewheel_wrapper' key={`scale(${crp[i]?.scale})`} src={`${constants.SOCKET_URL}${selFile.result}`} style={{
                      transform: `scale(${crp[i]?.scale})`
                    }} />
                    <div
                      className='bg-white mousewheel_wrapper'
                      style={{
                        width: "100%",
                        aspectRatio: selFile.dimension?.w / selFile.dimension?.h
                      }}
                    >
                    </div>
                  </ReactCrop>
                </Col>
                <Col span={8}>
                  <h2>Print Preview</h2>
                  <img className='border-4 border-white border-solid w-full' src={previewImage(i)} />
                  <h2 className="text-center">x {selFile[`print${_}`]}</h2>

                  <Button
                    icon={<PiFrameCornersLight className='mr-.5' />}
                    className='flex items-center'
                    onClick={() => {
                      let crop = centerCrop(
                        makeAspectCrop(
                          {
                            // You don't need to pass a complete crop into
                            // makeAspectCrop or centerCrop.
                            unit: '%',
                            width: 100,
                          },
                          (Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 1 : 0]) / Number(PRINT_SIZES[`print${_}`].split('x')[landscape ? 0 : 1])),
                          selFile.dimension?.w,
                          selFile.dimension?.h
                        ),
                        selFile.dimension?.w,
                        selFile.dimension?.h
                      );
                      if (crop.height == 100) {
                        let tmp = [...crp];
                        // console.log(e, e.deltaY, tmp[i].scale);
                        tmp[i] = { ...tmp[i], ...crop, scale: crop.width / 100 };
                        if (tmp[i].scale > 1) {
                          tmp[i].scale = 1;
                        } else if (tmp[i].scale <= 0.3) {
                          tmp[i].scale = 0.3;
                        }
                        setCrp(tmp);
                      } else {
                        let tmp = [...crp];
                        // console.log(e, e.deltaY, tmp[i].scale);
                        tmp[i] = { ...tmp[i], ...crop, scale: crop.height / 100 };
                        if (tmp[i].scale > 1) {
                          tmp[i].scale = 1;
                        } else if (tmp[i].scale <= 0.3) {
                          tmp[i].scale = 0.3;
                        }
                        setCrp(tmp);
                      }
                    }}
                  >Fit to Frame</Button>
                  <div className="mt-4">
                    <Radio.Group
                      key='landscape'
                      options={[
                        { label: "Landscape", value: true },
                        { label: "Portrait", value: false },
                      ]}
                      onChange={({ target: { value } }) => {
                        console.log(value);
                        setLandscape(value);
                        savePhotoDir(selFile.orderId, { index: selFile.index, landscape: value }).then(res => {
                          // console.log(res);
                          // getOrder();
                        }).catch(err => console.log(err));
                        let tmp = [];
                        for (const i of [1, 2, 3, 4, 5]) {
                          if (selFile[`print${i}`] > 0) {
                            // console.log(`print${i}`, selFile[`print${i}`])
                            tmp.push({
                              ...centerCrop(
                                makeAspectCrop(
                                  {
                                    // You don't need to pass a complete crop into
                                    // makeAspectCrop or centerCrop.
                                    unit: '%',
                                    width: 100,
                                  },
                                  (Number(PRINT_SIZES[`print${i}`].split('x')[value ? 1 : 0]) / Number(PRINT_SIZES[`print${i}`].split('x')[value ? 0 : 1])),
                                  selFile.dimension.w,
                                  selFile.dimension.h
                                ),
                                selFile.dimension.w,
                                selFile.dimension.h
                              ),
                              paper: `print${i}`,
                              count: selFile[`print${i}`]
                            });
                          }
                        }

                        console.log("tmp", tmp);
                        // let selFilePrint = selFile.print || [];

                        // tmp.forEach((item) => {
                        //   selFilePrint = selFilePrint.map(item1 => {
                        //     if (item1.paper === item.paper) {
                        //       return item1;
                        //     } else {
                        //       return item1;
                        //     }
                        //   });
                        // });

                        setCrp([...tmp]);
                        setPSetting([...tmp]);
                      }}
                      value={landscape} optionType="button"
                    />
                  </div>
                </Col>
              </Row>,
            };
          })}
        />
      }
      <div className='text-right mt-4'>
        <Button className='' onClick={previous}>
          Previous
        </Button>
        <Popconfirm
          key="confirm"
          title="Are you sure to setup settings?"
          description="Please make sure you have set up all print settings."
          onConfirm={() => {
            let tmp = additionalOrder.fileList.map(file => ({ ...file, dimension: { ...file.dimension, landscape: landscape } }));
            tmp[0].print = crp;
            updateData("fileList", tmp);
            next();
            // setSaving(true);

            // savePrintSetting(selFile.orderId, { index: selFile.index, print: crp }).then(async res => {
            //   // await getOrder();
            //   message.success("Successfully saved!");
            //   // setIsPrStModalOpen(false);
            // }).catch(err => console.log(err)).finally(() => setSaving(false));
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type='primary' className='ml-2' key="handleOk" loading={saving}>Next</Button>
        </Popconfirm>
      </div>
    </div>
  )
}

export default PrintSettingContent;
