import React, { useEffect, useState } from 'react';
import { Col, Row, Badge, Button, message, Divider, Input, Flex, Popconfirm } from 'antd';
import { saveAs } from 'file-saver';
import { FaThumbsUp } from 'react-icons/fa6';
import { IoMdPrint } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { DownloadOutlined, LockOutlined, PrinterOutlined } from '@ant-design/icons';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import AdditionalPrintsModal from './Partials/AdditionalPrintsModal';
import ApproveConfirmationModal from './Partials/ApproveConfirmationModal';
import PrintSettingsModal from './Partials/PrintSettingsModal';
import constants, { ORDER_STATUS, PHOTO_STATE, PHOTO_STATUS } from '../../../config/constants';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { /* analyzePhoto, */ approveResult, getOrdersById, updateRequest } from '../../../services/orderAPI';

import 'react-image-crop/dist/ReactCrop.css';

const Result = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [note, setNote] = useState({});
  const [user, setUser] = useState({});

  // const user = useSelector(state => state.auth.user);

  const [selectedFileIndex, setSelectedFileIndex] = useState(undefined);
  const [isPrStModalOpen, setIsPrStModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isAdditionalPrintsModalOpen, setIsAdditionalPrintsModalOpen] = useState(false);

  const getOrder = async () => {
    try {
      let res = await getOrdersById(id);
      setOrder(res.data.order);
      setUser(res.data.order.userId);
    } catch (err) {
      console.log(err);
    }
  }

  const handleDownload = (url, index) => {
    saveAs(url, `photo_${id}_${index}.jpg`);
  }

  const handleClick = async (file, index) => {
    if (!order.paymentId) {
      // setStorage('prev_url', id);
      const { _id, createdAt, paymentId, refunded, status, updatedAt, userId, __v, ...rest } = order;
      dispatch(updateOrderDetail({
        value: {
          orderId: _id,
          name: userId.name,
          email: userId.email,
          ...rest
        }
      }))
      navigate('/order/checkout');
    } else {
      handleDownload(`${constants.SOCKET_URL}${file.result}`, index);
    }
  }

  const isShipped = (file) => {
    return file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0 || file.additionalPrint1 > 0 || file.additionalPrint2 > 0 || file.additionalPrint3 > 0 || file.additionalPrint4 > 0 || file.additionalPrint5 > 0;
  }

  useEffect(() => {
    getOrder();
  }, []);
  
  return (
    <PublicLayout>
      <div className='p-4'>
        <Row gutter={32} justify={'center'} align={"stretch"}>
          {
            order ? 
              order.fileList?.map((file, index) => 
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  <Flex vertical 
                    justify="center" 
                    align="center"
                    className="h-full"
                  >
                    <Badge.Ribbon color="#CB2B8377" 
                      text={
                        <div className='py-1'>
                          {
                            file.colorize && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Colorize</span>
                              </p>
                          }
                          {
                            !isShipped(file) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; No Print</span>
                              </p>
                          }
                          {
                            (file.print1 > 0 || file.additionalPrint1 > 0) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Print 4x6:</span> {file.print1}{file.additionalPrint1 > 0 && <span>(+{file.additionalPrint1})</span>}
                              </p>
                          }
                          {
                            (file.print2 > 0 || file.additionalPrint2 > 0) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Print 5x7:</span> {file.print2}{file.additionalPrint2 > 0 && <span>(+{file.additionalPrint2})</span>}
                              </p>
                          }
                          {
                            (file.print3 > 0 || file.additionalPrint3 > 0) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Print 8x10:</span> {file.print3}{file.additionalPrint3 > 0 && <span>(+{file.additionalPrint3})</span>}
                              </p>
                          }
                          {
                            (file.print4 > 0 || file.additionalPrint4 > 0) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Print 11x14:</span> {file.print4}{file.additionalPrint4 > 0 && <span>(+{file.additionalPrint4})</span>}
                              </p>
                          }
                          {
                            (file.print5 > 0 || file.additionalPrint5 > 0) && 
                              <p className='m-0 leading-none'>
                                <span className="font-bold">&#9679; Print 16x20:</span> {file.print5}{file.additionalPrint5 > 0 && <span>(+{file.additionalPrint5})</span>}
                              </p>
                          }
                        </div>
                      }
                    >
                      <ImgComparisonSlider className='slider-split-line'>
                        <figure slot="first" className="h-full before">
                          <img 
                            className='w-full h-full' 
                            src={`${constants.SOCKET_URL}${file.path}`} 
                            alt="before" 
                          />
                          <figcaption>Before</figcaption>
                        </figure>
                        <figure slot="second" className="h-full after">
                          <img 
                            className='w-full h-full' 
                            src={`${constants.SOCKET_URL}${file.result}`} 
                            alt="after" 
                          />
                          <figcaption>After</figcaption>
                        </figure>
                        <svg 
                          slot="handle" 
                          className="custom-animated-handle" 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="100" 
                          viewBox="-8 -3 16 6"
                        >
                          <path stroke="#000" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" strokeWidth="1" fill="#fff" vectorEffect="non-scaling-stroke"></path>
                        </svg>
                      </ImgComparisonSlider>
                    </Badge.Ribbon>

                    <div className='mt-4 w-full text-center'>
                      {
                        isShipped(file) && 
                        <>
                          <Popconfirm
                              key="confirm"
                              title="Are you sure?"
                              description="Please make sure you review your image before approval, after the proof is approved no more edits can be made."
                              onConfirm={() => {
                                approveResult(id, { index })
                                  .then(async res => {
                                    message.success('Successfully approved!');
                                    await getOrder();

                                    setSelectedFileIndex(index);
                                    setIsPrStModalOpen(true);
                                    
                                }).catch(err => {
                                  message.warning("Something went wrong!");
                                })
                              }}
                              okText="Yes"
                              cancelText="No"
                          >
                            <Button 
                              type='primary' 
                              block 
                              size="large" 
                              className='mb-2' 
                              icon={<FaThumbsUp />} 
                              disabled={file.approved >= PHOTO_STATE.Approved} 
                            >
                              {
                                file.approved > 1 ? 
                                  (file.approved === PHOTO_STATE.Printing && order.status == ORDER_STATUS.PendingApprove ?
                                      "Printing" : PHOTO_STATUS[file.approved]) : "Approve for Print"
                              }
                            </Button>
                          </Popconfirm>
                          <Button 
                            type='primary' 
                            block 
                            size="large" 
                            className='mb-2'
                            icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} 
                            onClick={() => handleClick(file, index)}
                          >
                            {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                          </Button>
                          {
                            file.approved >= PHOTO_STATE.Approved && 
                            <Button 
                              type='primary' 
                              className='flex justify-center items-center my-2' 
                              block 
                              size="large" 
                              icon={<IoMdPrint />} 
                              onClick={() => {
                                console.log("selectedFileIndex", index);
                                setSelectedFileIndex(index);
                                setIsPrStModalOpen(true);
                              }}
                            >
                              Print Settings
                            </Button>
                          }

                          <Button type="primary" 
                            size="large"
                            block
                            icon={<PrinterOutlined />} 
                            className='bg-[#FF3D00] hover:!bg-[#FF3D00]/80 mb-2 text-white'
                            onClick={() => {
                              if (file.approved < PHOTO_STATE.Approved) {
                                message.warning("Please approve your photo first!");
                              } else {
                                setSelectedFileIndex(index);
                                setIsAdditionalPrintsModalOpen(true);
                              }
                            }}
                          >
                            Order Additional Prints
                          </Button>

                          <Divider>Any Issues?</Divider>
                          <Input.TextArea
                            size='large'
                            placeholder="Please describe what needs to be fixed"
                            autoSize={{
                              maxRows: 5,
                              minRows: 2
                            }}
                            value={note[index]}
                            disabled={file.approved > 1}
                            onChange={e => setNote(prev => ({
                              ...prev,
                              [index]: e.target.value
                            }))}
                          />
                          <Button
                            block
                            size="large"
                            className='my-2'
                            type='primary'
                            disabled={file.approved > 1}
                            onClick={() => {
                              if (!note) return message.warning("Please describe the issues.");
                              updateRequest(id, { index, note: note[index] }).then(res => {
                                message.success('Successfully submited! We will try to restore again.');
                              }).catch(err => {
                                message.warning("Something went wrong!");
                              })
                            }}>
                            Submit
                          </Button>
                        </>
                      }
                      {
                        !isShipped(file) && 
                        <>
                          <Button type='primary' 
                            className='mb-2' 
                            block 
                            size="large" 
                            icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} 
                            onClick={() => handleClick(file, index)}
                          >
                            {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                          </Button>
                          
                          <Button type="primary" 
                            size="large"
                            icon={<PrinterOutlined />} 
                            className='bg-[#FF3D00] hover:!bg-[#FF3D00]/80 mb-2 text-white'
                            block
                            onClick={() => {
                              setSelectedFileIndex(index);
                              setIsAdditionalPrintsModalOpen(true);
                            }}
                          >
                            Order Additional Prints
                          </Button>

                          <Divider>Any Issues?</Divider>
                          <Input.TextArea
                            size='large'
                            placeholder="Please describe what needs to be fixed"
                            autoSize={{
                              maxRows: 5,
                              minRows: 2
                            }}
                            value={note[index]}
                            // disabled={file.approved > 1}
                            onChange={e => setNote(prev => ({
                              ...prev,
                              [index]: e.target.value
                            }))}
                          />
                          <Button
                            block
                            size="large"
                            className='my-2'
                            type='primary'
                            // disabled={file.approved > 1}
                            onClick={() => {
                              if (!note) return message.warning("Please describe the issues.");
                              updateRequest(id, { index, note: note[index] }).then(res => {
                                message.success('Successfully submited! We will try to restore again.');
                              }).catch(err => {
                                message.warning("Something went wrong!");
                              })
                            }}>
                            Submit
                          </Button>
                        </>
                      }
                    </div>
                  </Flex>
                </Col>
              )
            : <h1 className='text-2xl'>Order Not Found!</h1>
          }
          <Col span={24}>
            <p className='mt-2 text-center text-gray-400'>
              Any Issues? <a href="mailto:hello@fixphotos.ai">Let us Know.</a>
            </p>
          </Col>
        </Row>
      </div>
      <PrintSettingsModal 
        isPrStModalOpen={isPrStModalOpen} 
        setIsPrStModalOpen={setIsPrStModalOpen} 
        order={order} 
        selectedFileIndex={selectedFileIndex} 
        getOrder={getOrder}
      />
      <ApproveConfirmationModal isOpen={isApproveModalOpen} setIsOpen={setIsApproveModalOpen} />
      <AdditionalPrintsModal 
        isOpen={isAdditionalPrintsModalOpen} 
        setIsOpen={setIsAdditionalPrintsModalOpen}
        order={order}
        user={user}
        selectedFileIndex={selectedFileIndex}
        getOrder={getOrder}
      />
    </PublicLayout>
  )
}

export default Result;