import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography, notification, Steps, Form, message, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Address from './Address';
import DeliveryOptions from './DeliveryOptions';
import PaymentProcess from './PaymentProcess';
import PrintSettingContent from './PrintSettingContent';
import PrintSizes from './PrintSizes';
import constants, { ORDER_STATUS, PHOTO_STATE } from '../../../../config/constants';
import { useSocket } from '../../../../context/socket';
import { updateAdditionalOrderDetail } from '../../../../redux/additionalOrder/additionalOrderSlice';

import { createAdditionalPrint } from '../../../../services/additionalPrintAPI';
import { printingPhotoPackage } from '../../../../services/orderAPI';

const { Text } = Typography;
const stripePromise = loadStripe(constants.stripePK);

const AdditionalPrintsModal = ({ isOpen, setIsOpen, order, user, selectedFileIndex, getOrder }) => {

    const socket = useSocket();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [api, contextHolder] = notification.useNotification();

    const additionalOrder = useSelector(state => state.additionalOrder);
    const [form] = Form.useForm();
    const [current, setCurrent] = useState(0);
    const [isComplete, setIsComplete] = useState(false);

    const onClose = () => {
        setCurrent(0);
        setIsOpen(false);
    }
    const checkPrints = () => {

        let added = additionalOrder.fileList.some(file =>
            file.print1 > 0 ||
            file.print2 > 0 ||
            file.print3 > 0 ||
            file.print4 > 0 ||
            file.print5 > 0);

        if (!added) {
            notification.warning({
                message: "Please add at least one print size",
                showProgress: true,
            });
            return false;
        }

        return true;
    }

    const updateData = (field, value) => {
        dispatch(updateAdditionalOrderDetail({ field, value }));
    }

    const handleCheckIsNeedPrintSettings = (file, paper) => {

        let isNeedPrintSettings = false;

        if (file[paper] > 0) {
            console.log(paper, file[paper], file.print.filter(item => item.paper === paper));
            if (!file.print) {
                isNeedPrintSettings = true;
            } else if (file.print.filter(item => item.paper === paper).length === 0) {
                isNeedPrintSettings = true;
            }
        }

        return isNeedPrintSettings;
    }

    const update = async () => {

        createAdditionalPrint({
            orderId: order._id,
            userId: order.userId,
            address: additionalOrder.address,
            fileList: additionalOrder.fileList,
            delivery: additionalOrder.delivery,
            status: PHOTO_STATE.Printing,
        }).then(res => {
            console.log(res);
            getOrder();
        });

        // if (!isNeedPrintSettings) {

            printingPhotoPackage({
                userId: order.userId,
                orderId: order._id,
                fileList: additionalOrder.fileList,
                delivery: additionalOrder.delivery,
                address: additionalOrder.address,
                username: user.name,
            }).then(res => {

                message.success("Successfully requested!");
                getOrder();

            }).catch(err => {
                console.log(err);
                if (err.response?.data?.message)
                    message.warning(err.response.data.message);
            });
        // }

        setIsOpen(false);
    }

    const next = () => {
        if (current === 0 && !checkPrints()) {
            return;
        }

        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    }

    useEffect(() => {
        if (socket) {
            socket.on("charge.succeeded", async data => {
                message.success("Successsfully purchased!");
                if (data.orderId) {
                    navigate(`/order/${data.orderId}/result`);
                } else {
                    navigate('/order/success?additional=true');
                }
            });
        }
        return () => {
            if (socket) {
                socket.off('charge.succeeded');
            }
        }
    }, [socket]);

    useEffect(() => {
        if (isOpen) {
            console.log("selectedFileIndex", selectedFileIndex );
            console.log("order", order);

            setCurrent(0);

            let fileList = [];
            if (selectedFileIndex === undefined) {
                order.fileList.forEach(file => {
                    fileList.push({
                        path: file.path,
                        result: file.result,
                        print1: 0,
                        print2: 0,
                        print3: 0,
                        print4: 0,
                        print5: 0,
                        dimension: file.dimension,
                        // print: file.print,
                    })
                });
            } else {
                fileList = [
                    {
                        path: order.fileList[selectedFileIndex].path,
                        result: order.fileList[selectedFileIndex].result,
                        print1: 0,
                        print2: 0,
                        print3: 0,
                        print4: 0,
                        print5: 0,
                        dimension: order.fileList[selectedFileIndex].dimension,
                        // print: order.fileList[selectedFileIndex].print,
                    }
                ];
            }

            console.log("fileList", fileList);

            dispatch(updateAdditionalOrderDetail({
                value: {
                    ...order,
                    orderId: order._id,
                    name: user.name,
                    email: user.email,
                    address: order.address,
                    fileList: fileList,
                    delivery: order.delivery,
                }
            }));

            form.setFieldsValue({
                name: user.name,
                email: user.email,
            });
        }
    }, [isOpen, selectedFileIndex, order]);

    useEffect(() => {
        console.log("additionalOrder", additionalOrder);
    }, [additionalOrder]);

    return (
        <>
            {contextHolder}
            <Modal
                title={<Text className='text-xl'>Additional Prints</Text>}
                open={isOpen}
                onCancel={onClose}
                width={900}
                maskClosable={false}
                footer={[
                    <Flex key='footer' gap="small" justify="end">
                        {current !== 1 &&
                            <>
                                {current > 0 && (
                                    <Button
                                        key="prev"
                                        onClick={() => prev()}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {current < 4 && (
                                    <Button
                                        key="next"
                                        type="primary"
                                        onClick={() => next()}
                                    >
                                        Next
                                    </Button>
                                )}
                                {/* {current === 3 && (
                            <Button 
                                key="order"
                                type="primary" 
                                onClick={() => message.success('Processing complete!')}
                            >
                                Order
                            </Button>
                        )} */}
                            </>}
                    </Flex>
                ]}
            >
                <Steps
                    size="small"
                    current={current}
                    items={[
                        { title: "Select Print Sizes" },
                        { title: "Print Settings" },
                        { title: "Address" },
                        { title: "Delivery" },
                        { title: "Payment" },
                    ]}
                    className='mt-4 mb-8'
                />
                {
                    current === 0 &&
                    <PrintSizes
                        key={selectedFileIndex}
                        fileList={additionalOrder.fileList}
                        updateData={updateData}
                        isMorePrints={true}
                    />
                }
                {
                    current === 1 &&
                    <PrintSettingContent
                        order={order}
                        selectedFileIndex={selectedFileIndex}
                        updateData={updateData}
                        previous={() => prev()}
                        next={() => next()}
                    />
                }
                {
                    current === 2 &&
                    <Form
                        name="register"
                        form={form}
                        className="form"
                        scrollToFirstError
                    >
                        <Address
                            isShipped={true}
                            setIsComplete={setIsComplete}
                            orderData={additionalOrder}
                            updateData={updateData}
                            isMorePrints={true}
                        />
                    </Form>
                }
                {
                    current === 3 &&
                    <DeliveryOptions
                        initialDelivery={order.delivery}
                        updateData={updateData}
                    />
                }
                {
                    current === 4 &&
                    <Elements stripe={stripePromise} nonce="random-nonce">
                        <PaymentProcess
                            orderData={additionalOrder}
                            isComplete={isComplete}
                            form={form}
                            isMorePrints={true}
                            update={update}
                        />
                    </Elements>
                }
            </Modal>
        </>
    );
}

export default AdditionalPrintsModal;




















